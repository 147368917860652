<template>
  <div class="activity">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item v-for="(item, idx) in coverList" :key="idx">
        <img :src="item" />
      </van-swipe-item>
    </van-swipe>
    <div class="activity_info">
      <div class="title">{{ activity.title }}</div>
      <div class="item">
        <span class="label">联系方式：</span><span class="value">{{ activity.contactWay }}</span>
      </div>
      <div class="item">
        <span class="label">人数限制：</span><span class="value">{{ activity.personLimit }} 人/队</span>
      </div>
      <div class="item">
        <span class="label">活动时间：</span><span class="value">{{ activity.startDate }} 至 {{ activity.endDate }}</span>
      </div>
    </div>
    <div class="teams">
      <div class="title">现有团队</div>
      <van-grid :column-num="5" :border="false">
        <van-grid-item v-for="(item,idx) in teamList" :key="idx">
          <img :src="item.wxUser.avatar" class="avatar" />
        </van-grid-item>
      </van-grid>
    </div>
    <div ref="freeTeamContent" class="content" />

    <!-- <div class="buyer-wrapper">
        <span class="title">最新办理：</span>
        <span v-for="(item, idx) in buyerQueue" :key="idx" class="avatar-queue">
          <img :src="item" />
        </span>
      </div> -->

    <!-- <div v-if="freeTeam.activityStatus === 0" class="share_wrapper" @click="openSharePic">生成专属海报</div>

    <van-popup v-model="showSharePic">
      <van-loading v-if="loading" type="spinner" size="24px">正在生成海报</van-loading>
      <img v-else ref="posterImg" :src="posterImg" />
    </van-popup> -->

    <!-- 当前登录：{{ userInfo.nickname }}
    <img :src="userInfo.avatar" style="width: 50px; border-radius: 50%" />
    loc:{{ loc }}<br />
    initLink:{{ initLink }} -->

    <van-goods-action v-if="activity.activityStatus === 0">
      <van-goods-action-button type="primary" text="团队列表" @click="toAllTeam" />
      <van-goods-action-button type="danger" text="我要组队" @click="toCreateTeam" />
      <van-goods-action-button v-if="teamId > 0" type="warning" text="加入此队" @click="toJoinTeam" />
    </van-goods-action>
    <van-goods-action v-else>
      <van-goods-action-button v-if="activity.activityStatus === -1" type="danger" text="活动未开始" />
      <van-goods-action-button v-if="activity.activityStatus === 1" type="danger" text="活动已结束" />
    </van-goods-action>

  </div>
</template>
<script>
import wxconfig from '@/wx'
import freeTeamApi from '@/api/free-team'
import wxUserApi from '@/api/wxuser'
import orderApi from '@/api/order'

import wxComPayApi from '@/api/wxcom-pay'

export default {
  data() {
    return {
      appId: -2,
      teamId: -1, // 团队ID
      activity: {
        id: '',
        title: '',
        descn: ''
      },
      showSharePic: false,
      coverList: [],
      loc: window.location.href,
      initLink: sessionStorage.getItem('initLink'),
      posterImg: '',
      loading: false,
      teamList: [],
      imgServer: process.env.VUE_APP_IMG_SERVER_URL
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.teamId = parseInt(this.$route.params.teamId)
    this.activity.id = this.$route.params.id
  },
  mounted() {
    this.loadActivityData()
    this.loadTeamList()
  },
  methods: {
    // 加载团队列表
    loadTeamList() {
      freeTeamApi.getTeamList(this.activity.id).then(res => {
        if (res.succ) {
          this.teamList = res.data.items
          if (this.teamList.length > 10) {
            this.teamList = this.teamList.slice(0, 10)
          }
        }
      })
    },
    // 显示图片分享层
    openSharePic() {
      this.showSharePic = true
      this.loading = true
      freeTeamApi.genSharePic(this.freeTeam.id, this.teamId).then(res => {
        this.loading = false
        const a = new FileReader()
        a.readAsDataURL(res)
        a.onload = e => {
          this.posterImg = e.target.result
        }
      })
    },
    toCreateTeam() {
      this.$router.push(`/${this.appId}/freeTeam/create/${this.activity.id}/-1`)
    },
    toJoinTeam() {
      this.$router.push(`/${this.appId}/freeTeam/create/${this.activity.id}/${this.teamId}`)
    },
    toAllTeam() {
      this.$router.push(`/${this.appId}/freeTeam/teamList/${this.activity.id}`)
    },
    // 创建订单，跳转到订单确认
    // createOrder() {
    //   orderApi.create({ activityId: this.activity.id }).then(res => {
    //     if (res.succ) {
    //       this.$router.push({ path: `/orderConfirm/${res.data.item}` })
    //     }
    //   })
    // },

    // 加载活动信息
    loadActivityData() {
      freeTeamApi.get(this.activity.id).then(res => {
        if (res.succ) {
          let data = res.data
          if (!data.item) {
            this.$toast('活动不存在')
          } else {
            this.activity = data.item
            this.initBanners(data)
            this.$refs.freeTeamContent.innerHTML = data.item.content
          }
        }
      })
    },
    // 初始化banners
    initBanners(data) {
      if (data.banners) {
        data.banners.forEach(i => {
          this.coverList.push(this.imgServer + '/' + i.filePath)
        })
      }
    },
    addViewer() {
      // 添加活动访问记录
      let data = {
        activityId: this.activity.id,
        viewerId: this.userInfo.id
      }
      freeTeamApi.addViewer(data).then(res => {
        if (res.succ) {
          console.log('添加成功')
        }
      })
    },
    initWxConfig() {
      // 配置微信信息
      wxconfig([this.initShareData])
    },
    initShareData() {
      // 配置分享信息
      let userInfoId = this.userInfo.id
      let link = process.env.VUE_APP_BASE_URL + `/${this.appId}/freeTeam/${this.activity.id}/${this.teamId}`
      // 分享给好友
      this.$wx.updateAppMessageShareData({
        title: this.activity.title,
        desc: this.activity.descn,
        link: link,
        imgUrl: this.activity.cover,
        success(res) {
          console.log('分享参数设置成功', res)
        },
        complete() {
          console.log('share complete')
        },
        fail(error) {
          console.log('设置分享参数错误：', JSON.stringify(error))
        }
      })
      // 分享到朋友圈
      this.$wx.updateTimelineShareData({
        title: this.activity.title,
        desc: this.activity.descn,
        link: link,
        imgUrl: this.activity.cover,
        success(res) {
          console.log('分享参数设置成功', res)
        },
        complete() {
          console.log('share complete')
        },
        fail(error) {
          console.log('设置分享参数错误：', JSON.stringify(error))
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.my-swipe {
  height: 280px;
  img {
    width: 100%;
    height: 280px;
  }
}
.activity {
  background-color: #fff;
  .activity_info {
    padding: 0.2rem;
    font-size: 0.36rem;
    .title {
      text-align: center;
      font-size: 0.6rem;
      padding: 0.2rem;
    }
    .item {
      height: 0.8rem;
      line-height: 0.8rem;
      .label {
        width: 1rem;
      }
      .value {
        color: #999;
        width: 1rem;
        text-align: left;
      }
    }
  }
  .teams {
    background-color: #fff;
    padding: 0 0.2rem;
    .title {
      border-left: 8px solid darkred;
      font-size: 0.4rem;
      font-weight: 500;
      padding-left: 0.1rem;
      margin-bottom: 0.2rem;
    }
    .van-grid {
      border: 1px dashed darkred;
      min-height: 1.8rem;
      img.avatar {
        width: 1.2rem;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
  }
}
.content {
  /deep/ p {
    img {
      display: block;
    }
  }
}

.team-members {
  min-height: 100px;
  background-color: #fff;
  .title {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
  }
  .van-grid {
    border: 2px dashed darkred;
    min-height: 50px;
    /deep/ .van-badge--fixed {
      transform: translate(25%, 25%);
    }
    img.avatar {
      border-radius: 50%;
      width: 48px;
    }
  }
}
.share_wrapper {
  position: absolute;
  right: 0;
  top: 36vh;
  display: block;
  background-color: darkred;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 2px 2px 2px 6px;
  font-size: 14px;
  font-weight: bold;
}
.van-popup {
  width: 84%;
  min-height: 60vh;
  // overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-loading {
    margin: 0 auto;
  }
  img {
    width: 100%;
    display: block;
  }
}
.buyer-wrapper {
  min-height: 40px;
  line-height: 40px;
  margin-bottom: 50px;
  overflow: auto;
  padding-left: 14px;
  .title {
    width: 90px;
    font-size: 14px;
    height: 40px;
    font-weight: bold;
    line-height: 40px;
    float: left;
    margin-left: -14px;
  }
  .avatar-queue {
    float: left;
    img {
      width: 36px;
      height: 36px;
      border: 1px solid #aaa;
      border-radius: 50%;
      margin-left: -14px;
    }
  }
}
.referee-wrapper {
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  display: flex;
  .title {
    width: 70px;
    font-size: 14px;
    height: 40px;
    font-weight: bold;
    line-height: 40px;
  }
  .referee-info {
    flex: 1;
    display: flex;
    .avatar-box {
      width: 40px;
      img {
        width: 36px;
        border: 1px solid #aaa;
        border-radius: 50%;
      }
    }
    .nickname {
      flex: 1;
      text-align: left;
      padding-left: 10px;
      color: #aaa;
    }
  }
}
</style>
