import request from '@/utils/request'

const prefix = '/api/activityOrder'

export default {
  create(params) {
    return request.post(`${prefix}/create`, params)
  },

  list(params) {
    return request.get(`${prefix}/orderList`, {params})
  },
  detail(orderNum) {
    return request.get(`${prefix}/${orderNum}`)
  },
  cancel(orderNum) {
    return request.put(`${prefix}/${orderNum}/cancel`)

  }
}
