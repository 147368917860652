import request from '@/utils/request'

const prefix = '/api/wxUser'

export default {
  getUserInfoById(id) {
    return request.get(`${prefix}/${id}`)
  }

}
